
main {
	margin: 0 auto;
	max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #012A4A;
  --secondary: #01497C;
  --tertiary: #014F86;
  --quaternary: #2A6F97;
  --quinary: #2C7DA0;
  --senary: #89C2D9;
  --light: #fafafa;
  --dark: #000000;
  --darker: #111111;
  --error: #BF0603;
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: var(--primary);
  color: var(--light);
  font-family: Helvetica, Arial, sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
	border-radius: 5px;
  color: var(--senary);
  text-decoration: none;
	font-weight: 600;
	padding: 2px 4px;
	margin-left: -2px;
	margin-right: -2px;	
  transition: all 0.3s ease-in-out;
}

a:hover {
  text-decoration: underline;
  background: var(--quinary);
  color: var(--primary);
  font-size: large;
}

.header {
  background: var(--senary);
  top: 0;
  opacity: 0.9;
}

.header > h2 > a {
  color: var(--secondary);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.header > h2 > a:hover {
  color: var(--primary);
  background: var(--senary);
  text-decoration: underline;
  font-size: xx-large;
}

.hero {
  background-image: url(./assets/images/cherryblossoms.jpg);
  background-size: cover;
  background-position: 70%;
  padding: 125px;
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
  color: var(--primary)
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 100%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

nav  li {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

nav  li:hover {
  font-size: large;
  color: var(--primary);
}

textarea {
	width: 600px;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
}

.icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
}

.card-text {
  color: var(--primary);
}

.skills {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: 20px;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 2fr;
  align-content: flex-start;
}

.resume {
  width: 85%;
  max-width: 1200px;
  height: 100%;
  align-content: center;
  margin: 0;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

.navActive {
  color: var(--secondary);
}

/* --------------------------------------------- */
/* Tooltip Styles
/* --------------------------------------------- */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle to point the tooltip */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.projects .card {
  border: 0.1rem solid white;
}

.projects .card .card-title {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.projects .card .card-text {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.projects .card .card-footer {
  opacity: 0;
  transition: all 0.3s ease-in-out;  
}

.projects a {
  opacity: 0;
  text-decoration: underline;
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.projects .card:hover img {
  opacity: 0.15;
}

.projects .card:hover .card-title {
  opacity: 1;
  color: var(--primary);
}

.projects .card:hover .card-text {
  opacity: 1;
}

.projects .card:hover a {
  opacity: 1;
}

.projects .card:hover .card-footer {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .projects .card .card-title, .projects .card .card-text, .projects .card a {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 600px) {
  .projects .card .card-title, .projects .card .card-text, .projects .card a {
    font-size: 0.5rem;
  }
  .projects .card .card-text {
    display: none;
  }
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-bottom: 130px;
}

.contact .info {
  padding: 30px;
  background: var(--secondary);
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact-info-item {
  padding: 1.2rem;
  margin: 1rem;
}

.info .contact-info-item i {
  font-size: 1.4rem;
  color: var(--tertiary);
  float: left;
  width: 44px;
  height: 44px;
  background: var(--light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #050d18;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: var(--senary);
}

.info .contact-info-item:hover i {
  background: var(--primary);
  color: #fff;
  font-size: 1.75rem;
}

.info .contact-info-item a {
  color: var(--senary);
  transition: all 0.3s ease-in-out;
}

.info .contact-info-item:hover a {
  color: var(--primary);
  background-color: var(--senary);
}

.contact .php-email-form {
  width: 100%;
  padding: 30px;
  background: var(--secondary);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
  width: 100%;
}

.contact .php-email-form .validate {
  display: none;
  color:  rgb(78, 5, 5);
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background:  rgb(78, 5, 5);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 15px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: var(--quaternary);
  color: var(--light);
  border: 0;
  padding: 10px 24px;
  transition: 0.4s;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact .php-email-form button[type="submit"]:hover {
  background: var(--senary);
  color: var(--primary);
  font-weight: bold;
}

.error-text {
  font-family: "Open Sans", sans-serif;
  color: rgb(78, 5, 5);
  font-size: 24px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
